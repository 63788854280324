<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Checkout</b> zatwierdź swoje zamówienie i oczekuj na przesyłkę.
      </div>
    </b-alert>

    <b-row>
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Checkout'">
          <template v-slot:toolbar-action>
            <div>
              <b-button
                variant="danger"
                size="sm"
                class="mb-1"
                @click="clearCart()"
              >
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <div>
              <div class="card-body pt-2 pb-0">
                <!--begin::Table-->
                <div class="table-responsive">
                  <table class="table table-vertical-center">
                    <thead>
                      <tr>
                        <th class="p-5"></th>
                        <th class="p-0">Nagroda</th>
                        <th class="p-0 text-center">Ilość</th>
                        <th class="p-0 text-center">Koszt [pkt]</th>
                        <th class="p-0 text-center">Koszt [pln]</th>
                        <th class="p-0 text-center">Akcje</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in getCart">
                        <tr v-bind:key="i">
                          <td class="pl-0 py-5">
                            <a href="#" class="symbol symbol-70 flex-shrink-0">
                              <img :src="getFirstImageUrl(item.prize.images)" />
                            </a>
                          </td>
                          <td class="pl-0">
                            {{ item.prize.erpPrize.code }}
                            {{ item.prize.erpPrize.name }}
                          </td>
                          <td class="text-center pr-0">
                            <span
                              class="text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ item.quantity }}
                            </span>
                          </td>
                          <td class="text-center">
                            <span
                              class="text-muted text-right font-weight-bold"
                            >
                              {{ item.prize.erpPrize.cost }}
                            </span>
                          </td>
                          <td class="text-center">
                            <span
                              class="text-muted text-right font-weight-bold"
                            >
                              {{ item.quantity * 1 }}
                            </span>
                          </td>                          
                          <td class="text-center">
                            <span class="text-muted font-weight-bold">
                              <a
                                href="#"
                                class="btn btn-xs btn-light-success btn-icon mr-2"
                                @click="decAmount(item.prize)"
                              >
                                <i class="ki ki-minus icon-xs"></i>
                              </a>
                              <a
                                href="#"
                                class="btn btn-xs btn-light-success btn-icon"
                                @click="incAmount(item.prize)"
                              >
                                <i class="ki ki-plus icon-xs"></i>
                              </a>
                              <span style="padding-left: 30px;">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-light-danger btn-icon"
                                  @click="removePrize(item.prize)"
                                >
                                  <b-icon icon="trash" font-scale="1"></b-icon>
                                </a>
                              </span>
                            </span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--end::table-->
                <!--begin::Address-->
                <div class="p-8">
                  <b-row>
                    <b-col sm="12" class="text-right">
                      <h2>Koszt
                          <b-badge variant="warning">{{ charge() }} pln</b-badge>
                      </h2>
                    </b-col>                  
                  </b-row>
                  <b-row>                  
                    <b-col sm="4">
                      <div class="text-left" v-if="pointsSum() > 0">
                        <label>Forma Płatności</label>
                        <b-form-select
                          v-model="delivery.paymentForm"
                          :options="paymentOptions"
                          @change="changePaymentForm($event)"
                        ></b-form-select>
                      </div>
                    </b-col>
                    <b-col sm="8"></b-col>
                  </b-row>
                  <b-row>                  
                    <b-col sm="4">
                      <div class="text-left" v-if="posVisible">
                        <div style="padding-top: 20px;">Odbierz swoje zamówienie osobiście</div>
                        <label>Wybierz punkt detaliczny</label>
                        <b-form-select
                          :options="posOptions"
                          v-model="posSelected"
                          @change="changePos($event)"
                        ></b-form-select>
                      </div>
                    </b-col>
                    <b-col sm="8"></b-col>
                  </b-row>                  
                  <div class="pt-10 text-left" v-if="pointsSum() > 0">
                    <b-button
                      v-b-toggle.collapse-address
                      variant="outline-primary"
                      >Miejsce dostawy</b-button
                    >
                  </div>
                  <div class="text-left">
                    <b-collapse id="collapse-address" class="mt-2" visible>
                      <b-card>
                        
                        <b-form-group>
                          <b-row>
                            <b-col sm="12" lg="6">
                              <div>
                                <label for="input-live">Imię i nazwisko / nazwa:</label>
                                <b-form-input
                                  v-model="delivery.name"
                                  placeholder="Imię i nazwisko / Firma"
                                ></b-form-input>
                              </div>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <div>
                                <label for="input-live">Ulica:</label>
                                <b-form-input
                                  v-model="delivery.street"
                                  placeholder="Ulica"
                                ></b-form-input>
                              </div>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-group>
                          <b-row>
                            <b-col sm="12" lg="6">
                              <div>
                                <label for="input-live">Kod pocztowy:</label>
                                <b-form-input
                                  v-model="delivery.postal"
                                  placeholder="Kod Pocztowy"
                                  maxlength="6"
                                ></b-form-input>
                              </div>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <div>
                                <label for="input-live"
                                  >Miasto/Miejscowość:</label
                                >
                                <b-form-input
                                  v-model="delivery.locality"
                                  placeholder="Miasto / Miejscowość"
                                ></b-form-input>
                              </div>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
                <!--end::Address-->
                <hr />
                <!--begin::Summary-->
                <div class="p-8">
                  <div
                    class="d-flex align-items-center justify-content-between mb-7"
                  >
                    <span class="font-weight-bold text-muted font-size-sm mr-2"
                      >Suma punktów</span
                    >
                    <span class="font-weight-bolder text-primary text-right"
                      >{{ pointsSum() }} pkt</span
                    >
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-7"
                  >
                    <span class="font-weight-bold text-muted font-size-sm mr-2"
                      >Dostępne punkty</span
                    >
                    <span class="font-weight-bolder text-primary text-right"
                      >{{ points }} pkt</span
                    >
                  </div>
                  <div class="text-right" v-if="pointsSum() > 0">
                    <b-button
                      variant="primary"
                      size="lg"
                      :disabled="canPlaceOrder()"
                      @click="order(delivery)"
                    ><b-spinner v-if="loading"></b-spinner>
                      Złóż Zamówienie
                    </b-button>
                  </div>
                </div>
                <!--end::Summary-->
              </div>
              <!--begin::Body-->
            </div>
          </template>
        </KTCodePreview>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import Commons from "@/core/services/commons.service";
import {
  ADD_TO_CART,
  ORDER,
  DELETE_FROM_CART,
  DESTROY_CART
} from "@/core/services/store/cart.module";

export default {
  name: "Checkout",

  data() {
    return {
      loading: true,
      posVisible: false,
      chargeAmount: 1,
      posSelected: null,
      points: 0,
      delivery: {
        paymentForm: "ZA_POBRANIEM",
        name: "",
        street: "",
        postal: "",
        locality: ""
      },
      paymentOptions: [
        { value: "GOTÓWKA/KARTA", text: "Gotówka / Karta" },
        { value: "ZA_POBRANIEM", text: "Za Pobraniem" }
      ],
      posOptions: [
        { value: null, text: "Wybierz" },
        { value: "Bąków", text: "Punkt Detaliczny Bąków" },
        { value: "Nowa Wieś Mała", text: "Punkt Detaliczny Nowa Wieś Mała" },
        { value: "Ziemiełowice", text: "Punkt Detaliczny Ziemiełowice" },
        { value: "Gołaczów", text: "Punkt Detaliczny Gołaczów" },
        { value: "Radzikowice", text: "Punkt Detaliczny Radzikowice" },
        { value: "Korfantów", text: "Punkt Detaliczny Korfantów" },
        { value: "Tworzyjanów", text: "Punkt Detaliczny Tworzyjanów" },
        { value: "Strzegom", text: "Punkt Detaliczny Strzegom" },
        { value: "Grodków", text: "Punkt Detaliczny Grodków" }
      ],
      pos: [
        {
          name: "Bąków",
          street: "Bąków",
          postal: "49-200",
          locality: "Grodków"
        },
        {
          name: "Nowa Wieś Mała",
          street: "Nowa Wieś Mała 26",
          postal: "49-200",
          locality: "Grodków"
        },
        {
          name: "Ziemiełowice",
          street: "Ziemiełowice 36",
          postal: "46-100",
          locality: "Namysłów"
        },
        {
          name: "Gołaczów",
          street: "Gołaczów 41a",
          postal: "59-225",
          locality: "Chojnów"
        },
        {
          name: "Radzikowice",
          street: "Radzikowice 1",
          postal: "48-381",
          locality: "Goświnowice"
        },
        {
          name: "Korfantów",
          street: "Powstańców Śląskich 11",
          postal: "48-317",
          locality: "Korfantów"
        },
        {
          name: "Tworzyjanów",
          street: "Tworzyjanów 40",
          postal: "58-124",
          locality: "Marcinowice"
        },      
        {
          name: "Strzegom",
          street: "Mostowa 7",
          postal: "58-150",
          locality: "Strzegom"
        },
        {
          name: "Grodków",
          street: "Otmuchowska 4e",
          postal: "49-200",
          locality: "Grodków"
        },         
      ]    
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    refreshPoints() {
      ApiService.get("/overal/points").then(result => {
        this.points = result.data.gainedPoints;
        this.loading = false;
      });
    },
    getImageUrl: Commons.getImageUrl,
    getFirstImageUrl(images) {
      if (images && images.length > 0) {
        return this.getImageUrl(images[0]);
      }
    },
    canPlaceOrder() {
      return this.points - this.pointsSum() < 0;
    },
    pointsSum() {
      return this.getCart
        .map(p => p.quantity * p.prize.erpPrize.cost)
        .reduce((l, r) => l + r, 0);
    },
    decAmount(prize) {
      this.$store.dispatch(ADD_TO_CART, { prize, quantity: -1 });
    },
    incAmount(prize) {
      this.$store.dispatch(ADD_TO_CART, { prize, quantity: 1 });
    },
    order(delivery) {
      this.loading = true;
      this.$store.dispatch(ORDER, { delivery }).then(() => {
        this.refreshPoints();
        Swal.fire({
          title: "Dziękujemy!",
          text: "Zamówienie zostało złożone",
          icon: "success",
          confirmButtonClass: "btn btn-success",
          heightAuto: false
        });
        this.$router.push({ name: "order-history" });
      });
    },
    removePrize(prize) {
      this.$store.dispatch(DELETE_FROM_CART, { prize });
    },
    clearCart() {
      this.$store.dispatch(DESTROY_CART);
    },
    changePaymentForm(event) {
      this.posVisible = (event == 'GOTÓWKA/KARTA') ? true : false; 
      this.delivery.paymentForm = event;
    },
    changePos(event) {
      if(event) {  
        var result = this.pos.filter(obj => {
          return obj.name === event
        });
        
        this.delivery = {
            paymentForm: this.delivery.paymentForm,
            name: result[0].name,
            street: result[0].street,
            postal: result[0].postal,
            locality: result[0].locality
        };
      }
      
      
    },
    charge() {
      return this.getCart
        .map(p => p.quantity * this.chargeAmount)
        .reduce((l, r) => l + r, 0).toFixed(2);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Checkout" },
      { title: "" }
    ]);
    this.refreshPoints();
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    ...mapGetters(["getCart"])
  }
};
</script>
